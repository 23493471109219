import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import { useI18next } from 'gatsby-plugin-react-i18next'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import Header from '@solid-ui-blocks/Header/Block01'
import Hero from '@solid-ui-blocks/Hero/Block02'
import Hero04 from '@solid-ui-blocks/Hero/Block04'
import FeatureTabThree from '@solid-ui-blocks/FeaturesWithPhoto/Block01'
import GetStarted from '@solid-ui-blocks/Stats/Block01'
import ContentBlock from '@solid-ui-blocks/Content/Block01'
import Footer from '@solid-ui-blocks/Footer/Block01'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import theme from '../_theme'
import styles from '../_styles'

const IndexPage = props => {
  const { t } = useI18next()
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes, t)

  return (
    <Layout theme={theme} {...props}>
      <Seo title={t('Strona główna')} />
      {/* Blocks */}
      <Header content={content['header']} />
      <Divider space='5' />
      <Container variant='full' sx={styles.heroContainer}>
        <Hero content={content['hero']} />
      </Container>
      <Divider space='5' />
      <ContentBlock content={content['content-text-1']} />
      <Divider space='5' />
      <ContentBlock content={content['content-text-2']} reverseOnlyDesktop />
      <Divider space='5' />
      <Container variant='wide' sx={styles.textOnImageContainer}>
        <Hero04 content={content['text-on-image']} />
      </Container>
      <Divider space='5' />
      <ContentBlock content={content['content-text-3']} />
      <Divider space='5' />
      <Container variant='wide' sx={styles.featuresContainer}>
        <FeatureTabThree content={content['feature-tab-1']} />
        <Divider space='5' />
        <FeatureTabThree content={content['feature-tab-2']} reverse />
        <Divider space='5' />
        <FeatureTabThree content={content['benefits']} />
      </Container>
      <GetStarted content={content['get-started']} />
      <Divider space='5' />
      <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query indexBlockContent($language: String!) {
    allBlockContent(filter: { page: { in: ["site/index", "shared"] } }) {
      nodes {
        ...BlockContent
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default IndexPage
